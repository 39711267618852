import styled from "styled-components";
import { color, fontSize, fontWeight, lineHeight, space, textAlign } from "styled-system";

const Heading5 = styled.h5`
  ${fontSize}
  ${fontWeight}
  ${color}
  ${space}
  ${textAlign}
  ${lineHeight}
`;

Heading5.defaultProps = {
  fontSize: "11px",
  lineHeight: "21px",
  fontWeight: 400
};

export default Heading5;