import React from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

const httpLink = new HttpLink({
  uri: `https://teatrasmenas.lt/graphql`
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

const Apollo = ({ children }) => (
  <ApolloProvider client={client}>
    {children}
  </ApolloProvider>
)

Apollo.propTypes = {
  children: PropTypes.node
}

export default Apollo
