import styled from "styled-components";
import {
  alignItems,
  alignSelf,
  flexDirection,
  flexWrap,
  height,
  justifyContent,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  position,
  space,
  textAlign,
  width
} from "styled-system";

const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
  ${width};
  ${height};
  ${minWidth};
  ${maxWidth};
  ${maxHeight};
  ${minHeight};
  ${justifyContent};
  ${alignItems};
  ${alignSelf};
  ${flexWrap};
  ${flexDirection};
  ${textAlign};
  ${space};
  ${position};
`;

export default Flex;