import styled from "styled-components";
import { borderRadius, height, maxWidth, minWidth, space, width } from "styled-system";

const ImageWrapper = styled.img`
  ${minWidth};
  ${maxWidth};
  ${width};
  ${height};
  ${space};
  ${borderRadius};
  ${props => props.round ? "border-radius: 50%;" : ""}
`;

export default ImageWrapper;
