import React from "react";
import PropTypes from "prop-types";
import { Flex } from "components/common/Responsive";
import styled from "styled-components";
import { space } from "styled-system";
import ImageWrapper from "components/common/ImageWrapper";
import facebookFooter from "static/images/facebook.svg";
import instagramFooter from "static/images/instagram.svg";
import youtubeFooter from "static/images/youtube.svg";
import emailIcon from "static/images/mail.svg";

const StyledLink = styled.a`
  ${space};
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  width: ${props => props.iconSize}px;
  height: ${props => props.iconSize}px;
  background-color: #000;
  border-radius: 50%;
`

const SocialIcons = ({ iconSize }) => (
  <Flex alignItems="center">
    <StyledLink
      href="https://www.facebook.com/Panev%C4%97%C5%BEio-Teatras-Menas-205472032328/"
      target="_blank"
      rel="noopener noreferrer"
      iconSize={iconSize}
      ml={[0, "12px", "12px"]}
    >
      <ImageWrapper src={facebookFooter} width={iconSize / 1.8} height={iconSize / 1.8} />
    </StyledLink>
    <StyledLink
      href="https://www.instagram.com/teatras.menas/"
      target="_blank"
      rel="noopener noreferrer"
      iconSize={iconSize}
      mx={"12px"}
    >
      <ImageWrapper src={instagramFooter} width={iconSize / 1.8} height={iconSize / 1.8} />
    </StyledLink>
    <StyledLink
      href="https://www.youtube.com/channel/UC1x3BCZ9FVnP-ZC3cEZzDsw"
      target="_blank"
      rel="noopener noreferrer"
      iconSize={iconSize}
    >
      <ImageWrapper src={youtubeFooter} width={iconSize / 1.8} height={iconSize / 1.8} />
    </StyledLink>
    <StyledLink
      href="mailto:teatras.menas@gmail.com"
      rel="noopener noreferrer"
      iconSize={iconSize}
      ml={"12px"}
    >
      <ImageWrapper src={emailIcon} width={iconSize / 1.8} height={iconSize / 1.8} />
    </StyledLink>
  </Flex>
);

SocialIcons.propTypes = {
  iconSize: PropTypes.number
};

export default SocialIcons;
