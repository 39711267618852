import styled from "styled-components";
import { color, fontSize, fontWeight, lineHeight, space, textAlign } from "styled-system";

const Heading4 = styled.h4`
  ${fontSize}
  ${fontWeight}
  ${color}
  ${space}
  ${textAlign}
  ${lineHeight}
`;

export default Heading4;
