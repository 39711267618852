import styled from "styled-components";
import { color, fontSize, lineHeight, space } from "styled-system";

const Paragraph = styled.p`
  ${fontSize}
  ${lineHeight}
  ${color}
  ${space}
`;

export default Paragraph;
