import React, { Suspense } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import Routes from './routes'
import globalStyles from './styles/globalStyles'
import theme from './styles/theme'
import Footer from 'components/Footer'
import '@fontsource/pt-sans'

const GlobalStyle = createGlobalStyle`
  ${globalStyles};
`

const App = () => {
  return (
    <BrowserRouter>
      <IntlProvider locale='lt'>
        <ThemeProvider theme={theme}>
          <GlobalStyle/>
          <Suspense fallback={<div />}>
            <Routes />
            <Footer />
          </Suspense>
        </ThemeProvider>
      </IntlProvider>
    </BrowserRouter>
  )
}

export default App
