import styled from "styled-components";
import { color, fontSize, fontStyle, fontWeight, lineHeight, space, textAlign, width } from "styled-system";

const UniversalText = styled.span`
  ${width};
  ${fontSize};
  ${fontWeight};
  ${color};
  ${space};
  ${textAlign};
  ${lineHeight};
  ${fontStyle};
  display: block;
`;

export default UniversalText;
