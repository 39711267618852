const theme = {
  breakpoints: ["768px", "1240px"],
  colors: {
    lightPink: "#FFEDF7",
    black: "#000000",
    white: "#FFFFFF",
    blue: "#557183"
  }
};

export default theme;
