import React from "react";
import styled from "styled-components";
import { Flex } from "components/common/Responsive";
import ImageWrapper from "components/common/ImageWrapper";
import { Heading5 } from "components/common/Typography";
import leftDecoration from "static/images/left-decoration.png";
import rightDecoration from "static/images/right-decoration.png";
import logo from "static/images/logo.png";
import SocialIcons from "components/common/SocialIcons";
import { useMediaQuery } from "react-responsive";

const FooterWrapper = styled(Flex)`
  background-color: #ffffff;
  z-index: 10;
  position: relative;
  height: 171px;
  width: 100%;
  align-items: center;
  @media (max-width: 767px) {
    background-color: ${(props) => props?.theme?.colors?.lightPink};
    height: auto;
  }
`;

const FooterBackground = styled(Flex)`
  position: absolute;
  top: 21px;
  bottom: 21px;
  left: 0;
  right: 0;
  @media (min-width: 768px) {
    background-color: ${(props) => props?.theme?.colors?.lightPink};
  }
  z-index: -1;
`;

const FooterContactsWrapper = styled(Flex)`
  @media (min-width: 1240px) {
    border-left: 2px solid ${(props) => props?.theme?.colors?.black};
    border-right: 2px solid ${(props) => props?.theme?.colors?.black};
  }
`;

const Logo = styled(ImageWrapper)`
  cursor: pointer;
`;

const Footer = () => {
  const isDesktop = useMediaQuery({ minWidth: 1240 });
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 767 });
  return (
    <FooterWrapper
      py={["24px", 0]}
      my={["0", "20px"]}
      justifyContent={["center", "center", "space-between"]}
      px={"12px"}
    >
      {!isMobile && <FooterBackground />}
      {isDesktop && <ImageWrapper src={leftDecoration} />}
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={["column", "row"]}
      >
        <Logo
          src={logo}
          width="243px"
          height="53px"
          alt="Panevėžio Teatras menas"
        />
        <FooterContactsWrapper
          flexDirection="column"
          px={"25px"}
          justifyContent="center"
          ml={[0, "10px"]}
          alignItems="center"
          pt={["12px", 0]}
          pb={["6px", 0]}
        >
          <Heading5 fontSize="13px">
            {"Vasario 16 – osios g. 19, Panevėžys"}
          </Heading5>
          <Heading5>
            {"Kasos darbo laikas: II-V 11-15 val. VI 17-18 val. VII 11-12 val."}
          </Heading5>
          <Heading5>
            {"Bilietų kasos telefonas "}
            <a href="tel:845468935">{"(845) 46 89 35"}</a>
          </Heading5>
        </FooterContactsWrapper>
        <SocialIcons iconSize={isDesktop ? 62 : 42} />
      </Flex>
      {isDesktop && <ImageWrapper src={rightDecoration} />}
    </FooterWrapper>
  );
};

export default Footer;
