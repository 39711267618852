import styledNormalize from "styled-normalize";

const globalStyle = `
  ${styledNormalize};
  body {
    font-family: 'PT Sans';
    font-weight: 400;
  }
  a {
    text-decoration: none;
    color: inherit;
    transition: color 0.1s ease-in;
    &:hover {
    }
  }
  input {
    padding: 0;
    border: none;
  }
  p, h1, h2, h3, h4, h5 {
    margin: 0;
  }
  input:focus, textarea:focus, select:focus {
    outline: none;
  }
`;

export default globalStyle;
