import styled from "styled-components";
import { color, fontSize, fontWeight, lineHeight, space, textAlign } from "styled-system";

const Heading2 = styled.h2`
  ${fontSize}
  ${fontWeight}
  ${color}
  ${space}
  ${textAlign}
  ${lineHeight}
`;

Heading2.defaultProps = {
  fontWeight: 400
};

export default Heading2;
