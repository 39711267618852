import React, { lazy } from "react"
import { Redirect, Route, Switch } from "react-router-dom";
const Repertoire = lazy(() => import("containers/Repertoire"))
const Home = lazy(() => import("containers/Home"))
const Performances = lazy(() => import("containers/Performances"))
const Performance = lazy(() => import("containers/Performance"))
const Creator = lazy(() => import("containers/Creator"))
const Theater = lazy(() => import("containers/Theater"))
const News = lazy(() => import("containers/News"))
const New = lazy(() => import("containers/New"))
const Educations = lazy(() => import("containers/Educations"))
const Education = lazy(() => import("containers/Education"))
const Festivals = lazy(() => import("containers/Festivals"))
const Festival = lazy(() => import("containers/Festival"))
const NotFound = lazy(() => import("containers/NotFound"))


const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route exact path="/spektakliai">
      <Performances />
    </Route>
    <Route exact path="/spektakliai/:slug">
      <Performance />
    </Route>
    <Route exact path="/edukacijos">
      <Educations />
    </Route>
    <Route exact path="/edukacijos/:slug">
      <Education />
    </Route>
    <Route exact path="/teatras/:slug">
      <Creator />
    </Route>
    <Route exact path="/repertuaras">
      <Repertoire />
    </Route>
    <Route exact path="/teatras">
      <Theater />
    </Route>
    <Route exact path="/naujienos">
      <News />
    </Route>
    <Route exact path="/naujienos/:slug">
      <New />
    </Route>
    <Route exact path="/festivaliai">
      <Festivals />
    </Route>
    <Route exact path="/festivaliai/:slug">
      <Festival />
    </Route>
    <Route path="/kurejai">
      <Redirect to="/teatras" />
    </Route>
    <Route path="*">
      <NotFound />
    </Route>
  </Switch>
);

export default Routes;
